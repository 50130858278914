import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box, CardContent,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography,
} from '@mui/material';
import CashCardTemplate from './cards/CashCardTemplate';
import { myDebugPrint } from '../../utils/debug';



const ReportCashSettings = ({ projectId, users_access, accessLevel }) => {
    const theme = useTheme();

    const [users, setUsers] = useState(users_access);

    const [newUser, setNewUser] = useState({ email: '', accessLevel: 'viewer' });

    const handleInputChange = (e) => {
        myDebugPrint(`users_access: ${users_access}`)
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleAddUser = () => {
        setUsers([...users, { ...newUser, accessStatus: true }]);
        setNewUser({ email: '', accessLevel: 'viewer' });
    };

    const toggleStatus = (index) => {
        const updatedUsers = users.map((user, i) =>
            i === index ? { ...user, accessStatus: !user.accessStatus } : user
        );
        setUsers(updatedUsers);
    };

    return (
        <CashCardTemplate
            key={`users_${projectId}`}
            title="Права доступа к проекту (Экспериментальная функция)"
            cardcontent={
                <CardContent>
                    {accessLevel &&
                        <Typography variant='body2'>
                            У вас нет прав на работу с правами доступа к проекту.
                        </Typography>
                    }
                    {!accessLevel &&
                        <Box>
                            <TableContainer>
                                <Table stripe='odd'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell variant='cashAnalysisHead'>
                                                Email
                                            </TableCell>
                                            <TableCell variant='cashAnalysisHead'>
                                                Тип доступа
                                            </TableCell>
                                            <TableCell variant='cashAnalysisHead'>
                                                Статус доступа
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user, index) => (
                                            <TableRow key={index} variant={index % 2 ? 'evenCashAnalysis' : 'oddCashAnalysis'}>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.getFriendlyAccessLevel()}</TableCell>
                                                <TableCell>{user.accessStatus ? 'Активен' : 'Прекращен'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography variant='body2'>
                                Для добавления пользователей свяжитесь с вашим менеджером.
                            </Typography>
                        </Box>
                    }
                </CardContent>
            }
        />

    );
};


export default ReportCashSettings;



