// src/components/BusinessDetailsSkeleton.js
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container, Tooltip, Input, InputBase, TextField } from '@mui/material';
import './styles/Dashboard.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Cancel, Check, CheckBoxRounded, CheckCircle, CheckOutlined, Edit } from '@mui/icons-material';
import StyledInputBase from '../forms/StyledInputBase';
import DynamicWidthTextField from '../forms/DynamicWidthTextField';
import CircleLoader from 'react-spinners/CircleLoader';
import { formatDate } from '../../utils/utils';

const BusinessDetailsSkeleton = ({
    entityName,
    widgetsBeforeTitle,
    h1,
    widgetsAfterTitleBeforeFilters,
    filteringWidgets,
    widgetsAfterFilters,
    mainContent,
    h1tooltip,
    projectNameEditFunction,
    projectNameEditIsFetching,
    forceEditNameState,
    callbackOpenCompanyFull,
    limitsRest,
    children,
    readOnly,
}) => {
    const scrollContainerRef = useRef();
    const [maxScrollExtent, setMaxScrollExtent] = useState(0);
    const [isEditModeProjectName, setIsEditmodeProjectName] = useState(false);
    const [newPName, setNewPName] = useState(entityName);

    useEffect(() => {
        const updateMaxScrollExtent = () => {
            if (scrollContainerRef.current) {
                setMaxScrollExtent(scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight);
            }
        };
        updateMaxScrollExtent();
        window.addEventListener('resize', updateMaxScrollExtent);
        return () => window.removeEventListener('resize', updateMaxScrollExtent);
    }, []);

    useEffect(() => {
        if (forceEditNameState != null) {
            setIsEditmodeProjectName(false);
            setNewPName(entityName);
        }
    }, [forceEditNameState])

    const scrollToStart = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const scrollToEnd = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ top: maxScrollExtent, behavior: 'smooth' });
        }
    };

    const toggleEditmodeProjectName = () => (setIsEditmodeProjectName(!isEditModeProjectName));

    return (
        <Container maxWidth="100%" sx={{ padding: 0, ml: 0, mr: 0 }}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
                {(widgetsBeforeTitle || h1 || widgetsAfterTitleBeforeFilters || filteringWidgets || widgetsAfterFilters) && (
                    <Box width="100%" height={1} />
                )}
                <Box
                    ref={scrollContainerRef}
                    component="div"
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    overflow="auto"
                    width="100%"
                >
                    <Box display="flex" justifyContent="center" width="100%">
                        <Box maxWidth="100%" width="100%" sx={{ padding: '5px' }}>
                            {!entityName ? <></> :
                                (
                                    !isEditModeProjectName ?
                                        <Box
                                            key={h1 + entityName}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h1 className='h1BusinessDetails' style={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}>{entityName}{!(!projectNameEditFunction) && !readOnly
                                                && <span onClick={() => toggleEditmodeProjectName()}> <Edit
                                                    className='pseudoHrefWoColor'
                                                    style={{ marginLeft: '20px', color: 'grey' }}
                                                /></span>}</h1>
                                            {
                                                (callbackOpenCompanyFull && limitsRest && limitsRest.detailedCorp > 0) ?
                                                    <Typography variant='body2' sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center'
                                                    }}
                                                        className='pseudoHrefWithColor decorationNone'
                                                        onClick={callbackOpenCompanyFull}
                                                    >
                                                        <span>
                                                            Открыть в режиме полного доступа
                                                        </span>
                                                        {limitsRest && limitsRest.detailedCorp &&
                                                            <span style={{ fontStyle: 'italic' }}>
                                                                &nbsp;(осталось {limitsRest.detailedCorp}{
                                                                    limitsRest.limitsRenewDate && <span>
                                                                        &nbsp;до {formatDate(limitsRest.limitsRenewDate)}
                                                                    </span>})
                                                            </span>
                                                        }
                                                    </Typography>
                                                    : (callbackOpenCompanyFull && limitsRest && limitsRest.detailedCorp < 1) ?
                                                        <Typography variant='body2' sx={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center'
                                                        }}
                                                        >
                                                            <span>
                                                                Чтобы открыть в режиме полного доступа, нужен дополнительный пакет подписки
                                                            </span>
                                                        </Typography>
                                                        :
                                                        <></>
                                            }
                                        </Box>
                                        :
                                        <form id='formProjectNameEdit' onSubmit={(e) => { e.preventDefault(); projectNameEditFunction(newPName); }}>
                                            <Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center'
                                                }}>
                                                    <DynamicWidthTextField
                                                        label="Название проекта"
                                                        variant="standard"
                                                        id="projectName"
                                                        value={newPName}
                                                        onChange={setNewPName}
                                                        sx={{ textAlign: 'right', }}
                                                    />
                                                    <span>
                                                        {!projectNameEditIsFetching &&
                                                            <span>
                                                                <span onClick={() => { projectNameEditFunction(newPName); }}>
                                                                    <CheckCircle
                                                                        className='pseudoHrefWithColor'
                                                                        style={{ marginLeft: '20px', color: 'green', fontSize: 35, cursor: 'pointer' }}
                                                                    />
                                                                </span>
                                                                <span onClick={() => { toggleEditmodeProjectName(); setNewPName(entityName) }}>
                                                                    <Cancel
                                                                        className='pseudoHrefWithColor'
                                                                        style={{ marginLeft: '20px', color: 'red', fontSize: 35, cursor: 'pointer' }}
                                                                    />
                                                                </span>
                                                            </span>
                                                        }
                                                        {projectNameEditIsFetching &&
                                                            <span style={{ marginLeft: '37px', marginRight: '37px', fontSize: 'xx-large' }}>
                                                                &nbsp;
                                                                <CircleLoader color="#8A4B9A" className='spinner' size={35} />
                                                                &nbsp;
                                                            </span>
                                                        }
                                                    </span>
                                                </Box>
                                            </Box>
                                        </form>
                                )
                            }
                            {widgetsBeforeTitle && widgetsBeforeTitle.map((widget, index) => <Box key={index}>{widget}</Box>)}
                            {h1 && (
                                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                    <Box variant="h2">
                                        <h2 className='h2BusinessDetails'>{h1}</h2>
                                    </Box>
                                    {h1tooltip && (
                                        <Tooltip title={h1tooltip}>
                                            <HelpOutlineIcon sx={{ color: 'blue' }} />
                                        </Tooltip>
                                    )}
                                </Box>
                            )}
                            {widgetsAfterTitleBeforeFilters && widgetsAfterTitleBeforeFilters.map((widget, index) => <Box key={index}>{widget}</Box>)}
                            {filteringWidgets && filteringWidgets.map((widget, index) => <Box key={index}>{widget}</Box>)}
                            {widgetsAfterFilters && widgetsAfterFilters.map((widget, index) => <Box key={index}>{widget}</Box>)}
                            {mainContent && mainContent.map((content, index) => <Box key={index}>{content}</Box>)}
                            {children}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container >
    );
};

BusinessDetailsSkeleton.propTypes = {
    entityName: PropTypes.string,
    widgetsBeforeTitle: PropTypes.arrayOf(PropTypes.node),
    h1: PropTypes.string.isRequired,
    widgetsAfterTitleBeforeFilters: PropTypes.arrayOf(PropTypes.node),
    filteringWidgets: PropTypes.arrayOf(PropTypes.node),
    widgetsAfterFilters: PropTypes.arrayOf(PropTypes.node),
    mainContent: PropTypes.arrayOf(PropTypes.node).isRequired,
    h1tooltip: PropTypes.node,
};

export default BusinessDetailsSkeleton;
