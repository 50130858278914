// components/OrderFormSection.js
import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Checkbox, Button, Box, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import ApiManager from '../../utils/api/api';
import CircleLoader from 'react-spinners/CircleLoader';
import { useSnackbar } from '../../context/SnackbarContext';
import { myDebugPrint } from '../../utils/debug';

const Spacer = styled('div')(({ height }) => ({
    height: height || '20px',
}));

function OrderFormSection() {
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(true);
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        files: [],
        acceptance: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValues.acceptance) {
            showSnackbar('Согласие с условиями обязательно!', 'error')
            return;
        }
        if (loading) return;
        setLoading(true);
        try {
            const response = await ApiManager.sendRequest({
                name: formValues.name,
                email: formValues.email,
                phone: formValues.phone,
            });
            myDebugPrint(`response: ${JSON.stringify(response)}`)
            if (response && response.status && response.status == 'success') {
                showSnackbar('Заявка отправлена! Скоро мы с вами свяжемся', 'success');
                setSent(true);
            } else {
                showSnackbar(`Невозможно отправить заявку. Если вы не робот, напишите на info@brpadvice.ru`, 'warning')
            }
        } catch (error) {
            showSnackbar(`Невозможно отправить заявку. Если вы не робот, напишите на info@brpadvice.ru`, 'warning')
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }} id="form">
            <Container maxWidth="md">
                <Spacer height="20px" />
                <Typography variant="h2" gutterBottom
                    sx={{
                        fontSize: '2.1em',
                        color: '#5E5C5C',
                        fontWeight: 'bold',
                    }}>
                    Оформить заявку на ФинРадар
                </Typography>
                <Typography variant="body1">
                    Напишите на <a href="mailto:info@brpadvice.ru">info@brpadvice.ru</a> или заполните форму:
                </Typography>
                <Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} sx={{ marginTop: 0 }}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Как к вам обращаться"
                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Телефон"
                                    name="phone"
                                    type="tel"
                                    value={formValues.phone}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    inputProps={{
                                        pattern: '[0-9()#&+*-=.]+',
                                        title: 'Принимаются только цифры и телефонные символы (#, -, * и т. д.).',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="acceptance"
                                            checked={formValues.acceptance}
                                            onChange={handleChange}
                                            required
                                        />
                                    }
                                    label="Отправка формы означает согласие на обработку предоставленных данных"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {loading &&
                                    <CircleLoader color="#8A4B9A" className='spinner' size={45} />
                                }
                                {!loading &&
                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                        <i className="fa fa-paper-plane" aria-hidden="true" style={{ marginRight: '8px' }}></i>
                                        Отправить
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                <Spacer height="40px" />
            </Container>
        </Box>
    );
}

export default OrderFormSection;
