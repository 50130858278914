import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Tabs, Tab, useMediaQuery, Divider, Tooltip } from '@mui/material';
import { inflowsColor, outflowsColor } from '../../../utils/vars';
import { formatDateRangeAsText, formatMonth, shortenCashNumbers } from '../../../utils/utils';
import Slicer from '../../common/Slicer';
import ToggleIconButton from '../../common/ToggleIconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import PaymentsTable from './PaymentsTable';
import CashCardTemplate from './CashCardTemplate';
import CounterpartiesTable from './CounterpartiesTable';
import DateFilter from '../../common/DateFilter';
import CounterpartiesTableWrapper from './CounterpartiesTableWrapper';
import FilterAlt from '@mui/icons-material/FilterAlt';
import SlicerAutocomplete from '../../common/SlicerAutocomplete';
import SlicerListOfTerms from '../../common/SlicerListOfTerms';

const TransactionsCard = ({ projectId, currencyCode, updateCashProjectPayment,
    filteringInProgress,
    minDate, maxDate, counterparties_list, accessLevel,
}) => {
    const [selectedCounterparties, setSelectedCounterparties] = useState([]);
    const [filteredDateStart, setFilteredDateStart] = useState(minDate);
    const [filteredDateEnd, setFilteredDateEnd] = useState(maxDate);
    const [forcePaymentsFetch, setForcePaymentsFetch] = useState(true);
    const [paymentPurposeNeedles, setPaymentPurposeNeedles] = useState([]);


    const [isSlicer, setIsSlicer] = useState(true);
    const [paymentsKey, setPaymentsKey] = useState(0); // Add a state to manage the key

    const toggleSlicer = () => setIsSlicer(!isSlicer);

    const applyFilterDates = (dateFrom, dateTo) => {
        setFilteredDateStart(dateFrom);
        setFilteredDateEnd(dateTo);
        setForcePaymentsFetch(!forcePaymentsFetch);
    }

    const applyCounterpartyFilters = (newSelected) => {
        setSelectedCounterparties(newSelected);
    }

    const applyPaymentPurposeNeedles = (newTerms) => {
        setPaymentPurposeNeedles(newTerms);
    }


    return (
        <CashCardTemplate
            title={"Реестр платежей"
                + formatDateRangeAsText({
                    dateStart: filteredDateStart,
                    dateEnd: filteredDateEnd,
                    minDate: minDate,
                    maxDate: maxDate,
                })
            }
            action={
                <ToggleIconButton
                    icon={<FilterAlt />}
                    isOn={isSlicer}
                    onSelectAll={toggleSlicer}
                    onDeselectAll={toggleSlicer}
                />
            }
            subheader={
                <span>
                    счета, открытые в руб., все типы транзакций
                </span>
            }
            cardcontent={
                <CardContent>
                    {isSlicer &&
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} key="slicer-indicators-counterparties">
                                    {counterparties_list && counterparties_list.length > 0 &&
                                        <SlicerAutocomplete
                                            title='Контрагент'
                                            subheader='Укажите все варианты названий контрагента, которые встречаются в выписках (используйте подсказки, чтобы выбрать нужные значения)'
                                            itemsDict={counterparties_list}
                                            keyForLabel='title'
                                            keyForId='needle'
                                            initialSelectedItems={selectedCounterparties}
                                            onToggle={null}
                                            onSelectAll={applyCounterpartyFilters}
                                            onDeselectAll={applyCounterpartyFilters}
                                            onApply={applyCounterpartyFilters}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} >
                                    <DateFilter
                                        filterTitle='Период'
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        filteredDateStart={filteredDateStart}
                                        filteredDateEnd={filteredDateEnd}
                                        onFilter={applyFilterDates}
                                        filteringInProgress={filteringInProgress}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} >
                                    <SlicerListOfTerms
                                        title='Поиск по назначениям платежей'
                                        subheader={
                                            <span>
                                                Добавьте&nbsp;
                                                <span className='pseudoHrefWithColor'>
                                                    <Tooltip title={'Термин - это слово, часть слова, номер или часть номера'}>
                                                        термины
                                                    </Tooltip>
                                                </span>
                                                &nbsp;для поиска. Поиск -&nbsp;
                                                <span className='pseudoHrefWithColor'>
                                                    <Tooltip title={'Если термин "яблоко", покажем только платежи в которых указано именно так. Не покажем "яблоки" с "и" в конце. Чтобы найти и то, и другое, укажите термин "яблок"'}>
                                                        строгий
                                                    </Tooltip>
                                                </span>
                                                , без учета&nbsp;
                                                <span className='pseudoHrefWithColor'>
                                                    <Tooltip title={'"Яблоко","ЯБЛОКО", "яБлОкО" - дадут одинаковый результат'}>
                                                        регистра
                                                    </Tooltip>
                                                </span>
                                                .
                                                Термины объединяем по&nbsp;
                                                <span className='pseudoHrefWithColor'>
                                                    <Tooltip title={'Покажем все назначения платежа, где есть хотя бы один из терминов'}>
                                                        "ИЛИ"
                                                    </Tooltip>
                                                </span>
                                            </span>
                                        }
                                        initialTerms={paymentPurposeNeedles}
                                        onApply={applyPaymentPurposeNeedles}
                                        onClearAll={applyPaymentPurposeNeedles}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <PaymentsTable
                        key={paymentsKey} // Add the key prop
                        projectId={projectId}
                        currencyCode={'810'}
                        recordsPerPage={25}
                        updateCashProjectPayment={updateCashProjectPayment}
                        filteredByDates={minDate != filteredDateStart || maxDate != filteredDateEnd}
                        filteredDateStart={filteredDateStart}
                        filteredDateEnd={filteredDateEnd}
                        filteredByTypes={null}
                        selectedCounterparties={selectedCounterparties}
                        forcePaymentsFetch={forcePaymentsFetch}
                        paymentPurposeNeedles={paymentPurposeNeedles}
                        readOnly={accessLevel && accessLevel.toLowerCase() == 'viewer'}
                    />
                </CardContent>
            }
        />
    );
};

export default TransactionsCard;


