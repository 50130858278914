// src/pages/Search.js
import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import DashboardSkeleton from '../components/dashboard/DashboardSkeleton';
import SearchResults from '../components/SearchResults';
import { myDebugPrint } from '../utils/debug';
import ApiManager from '../utils/api/api';
import { useSnackbar } from '../context/SnackbarContext';

const SearchBusiness = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isSearching, setIsSearching] = useState(false);

    const [entities, setEntities] = useState(null);
    const [person, setPerson] = useState(null);
    const [nothingFound, setNothingFound] = useState(false);

    const initialQuery = searchParams.get('q') ?? '';
    const showSnackbar = useSnackbar();

    const handleError = ({ errorMessage }) => {
        showSnackbar(errorMessage, 'error');
    };

    const handleSearch = async (signal) => {
        setEntities(null);
        setPerson(null);
        setNothingFound(false);
        const needle = initialQuery;
        if (!needle || needle.length < 2) {
            return false;
        }
        try {
            setIsSearching(true);
            const response = await ApiManager.searchEntities({ query: needle });

            if (!signal || !signal.aborted) {
                setEntities(response.entities);
                setPerson(response.personData);
                setNothingFound(
                    (!response.entities || response.entities.length === 0)
                    &&
                    (!response.personData || response.personData.length === 0)
                );
            }
        } catch (e) {
            if (!signal || !signal.aborted) {
                myDebugPrint(`handleSubmit error: ${e}, ${JSON.stringify(e)}`);
                handleError({ errorMessage: 'Ошибка при выполнении поиска. Повторите попытку через минуту. Связь с поддержкой info@brpadvice.ru' });
            }
        } finally {
            if (!signal || !signal.aborted) {
                setIsSearching(false);
            }
        }

    };

    useEffect(() => {
        const controller = new AbortController();
        handleSearch(controller.signal);

        return () => {
            controller.abort(); // Cancel the previous search if searchParams change
        };
    }, [searchParams.get('q')]);


    return (
        <DashboardSkeleton
            initialQuery={initialQuery}
            isSearching={isSearching}
            forceSearch={handleSearch}
        >
            <SearchResults
                key={searchParams.get('q') ?? 'emptysearch'}
                entities={entities}
                person={person}
                nothingFound={nothingFound}
                isSearching={isSearching}
            />
        </DashboardSkeleton>
    );
};

export default SearchBusiness;
