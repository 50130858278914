class UserAccess {
    static accessLevelDescriptions = {
        Viewer: 'Только просмотр',
        Editor: 'Изменение типов транзакций',
        Master: 'Выписки, платежки - любые изменения кроме прав доступа'
    };

    constructor(params) {
        this._email = params?.email?.toLowerCase() ?? '';
        this._name = params?.name ?? '';
        this._accessLevel = params?.AccessLevel ?? 'Viewer';
        this._accessStatus = params?.AccessStatus === 1;
    }

    /** ✅ Getter for Email */
    get email() {
        return this._email;
    }

    /** ✅ Setter for Email (Validates Format) */
    set email(newEmail) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
            throw new Error('Invalid email format');
        }
        this._email = newEmail.toLowerCase();
    }

    /** ✅ Getter for Name */
    get name() {
        return this._name;
    }

    /** ✅ Setter for Name */
    set name(newName) {
        if (newName.trim().length === 0) {
            throw new Error('Name cannot be empty');
        }
        this._name = newName;
    }

    /** ✅ Getter for Access Level */
    get accessLevel() {
        return this._accessLevel;
    }

    /** ✅ Setter for Access Level (Ensures Validity) */
    set accessLevel(newAccessLevel) {
        if (!['Viewer', 'Editor', 'Master'].includes(newAccessLevel)) {
            throw new Error('Invalid access level');
        }
        this._accessLevel = newAccessLevel;
    }

    /** ✅ Getter for Access Status */
    get accessStatus() {
        return this._accessStatus;
    }

    /** ✅ Setter for Access Status (Any Non-1 is False) */
    set accessStatus(newStatus) {
        this._accessStatus = newStatus === 1;
    }

    /** ✅ Returns a friendly string for Access Level */
    getFriendlyAccessLevel() {
        return UserAccess.accessLevelDescriptions[this._accessLevel] || 'Неизвестный уровень доступа';
    }

    /** ✅ Returns available access levels for form selection */
    static getAccessLevelOptions() {
        return Object.entries(UserAccess.accessLevelDescriptions).map(([key, value]) => ({
            value: key,
            label: value
        }));
    }

    /** ✅ Creates an instance from JSON */
    static fromJson(json) {
        if (!json.email) {
            throw new Error("Invalid JSON: Missing required fields");
        }

        if (!['Viewer', 'Editor', 'Master'].includes(json.AccessLevel)) {
            throw new Error(`Invalid AccessLevel: ${json.AccessLevel}`);
        }

        return new UserAccess({
            email: json.email,
            name: json.name,
            AccessLevel: json.AccessLevel,
            AccessStatus: json.AccessStatus ?? 1 // Default to true if not provided
        });
    }
}

export default UserAccess;
