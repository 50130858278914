import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

export const sectionsCash = {
    common: {
        label: 'Общий отчет',
        h1: 'Отчет по движению денежных средств',
    },
    risk: {
        label: 'Риски',
        h1: 'Риски: расчеты с организациями, требующими особого внимания',
    },
    transactions: {
        label: 'Реестр платежей',
        h1: 'Реестр платежей',
    },
    settings: {
        label: 'Настройки',
        h1: 'Настройки проекта',
    },
    // You can add more sections here
};


const SectionsNavigation = ({ projectId, currentSection, ownerId = null, ownerEmail = null, ownerFullname = null, accessLevel = null }) => {
    return (
        <Box>
            {
                (ownerId || ownerEmail) &&
                <Typography variant='body2'>
                    Владелец проекта: {ownerId ? ownerId + ((ownerEmail || ownerFullname) ? ' / ' : '') : ''}
                    {ownerEmail ? ownerEmail + (ownerFullname ? ' / ' : '') : ''}
                    {ownerFullname}
                    {accessLevel && accessLevel.toLowerCase() == 'viewer' && '. Доступ в режиме "Только чтение. Добавление выписок и изменение данных запрещены".'}
                </Typography>
            }
            <Typography variant="h6">
                {Object.keys(sectionsCash).map((sectionKey) => {
                    const section = sectionsCash[sectionKey];
                    if (sectionKey === currentSection) {
                        // If the section is the current one, render as plain text (not a link)
                        return (
                            <span key={sectionKey} style={{ marginRight: '50px' }}>
                                {section.label}
                            </span>
                        );
                    } else {
                        // If the section is not the current one, render as a link
                        return (
                            <span key={sectionKey} style={{ marginRight: '50px' }}>
                                <a
                                    href={`cash?project=${projectId}${sectionKey === 'common' ? '' : '&s=' + sectionKey}`}
                                    style={{
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        color: 'rgb(25, 118, 210)',
                                    }}
                                >
                                    {section.label}
                                </a>
                            </span>
                        );
                    }
                })}
            </Typography>
            <Divider />
        </Box>
    );
};

export default SectionsNavigation;
