import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ApiManager from '../../utils/api/api';
import { myLaunchURL } from '../../utils/utils';
import { myDebugPrint } from '../../utils/debug';
import { storeEmail, storeToken } from '../../utils/storage';
import TextField from '../forms/TextField';
import TermsCheckbox from '../forms/TermsCheckbox';
import SubmitButton from '../forms/SubmitButton';
import authValidationSchema from './authValidationSchema';
import './styles/Auth.css';
import { FiAtSign, FiLock, FiKey } from 'react-icons/fi';
import { useSnackbar } from '../../context/SnackbarContext';
import { HttpStatusCode } from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useNavigation } from '../../context/NavigationContext';



const AuthScreen = ({ analytics }) => {
    const [searchParams] = useSearchParams();
    const [authState, setAuthState] = useState(searchParams.get('action') == 'verify' ? 'verifyEmail' : (searchParams.get('action') == 'setPassword' ? 'passwordReset' : 'signIn'));
    const { handleNavigateToOrderForm } = useNavigation();
    const navigate = useNavigate();
    const [serverError, setServerError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(true);
    const showSnackbar = useSnackbar();
    const { login } = useAuth();

    const handleError = ({ errorMessage }) => {
        showSnackbar(errorMessage, 'error');
        setServerError(errorMessage);
    };

    const setAuthStateMiddleware = (newState) => {
        setAuthState(newState);
        setServerError('');
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleAgreedToTerms = () => {
        setAgreedToTerms(!agreedToTerms);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        if (authState == 'signUp' && !agreedToTerms) {
            setServerError('Использование сервиса возможно только при полном согласии с условиями');
            return false;
        }

        setLoading(true);
        setServerError('');
        try {
            let response;
            switch (authState) {
                case 'signIn':
                    response = await ApiManager.signIn({ email: values.email, rawPassword: values.password });
                    storeEmail(values.email);
                    storeToken(response.token);
                    login();
                    navigate('/search');
                    break;
                case 'signUp':
                    response = await ApiManager.signUp({ email: values.email, rawPassword: values.password });
                    setAuthStateMiddleware('verifyEmail');
                    break;
                case 'recoveryRequest':
                    response = await ApiManager.recoveryRequest({ email: values.email });
                    setAuthStateMiddleware('passwordReset');
                    break;
                case 'passwordReset':
                    response = await ApiManager.passwordReset({
                        email: values.email, rawPassword: values.password, recoveryToken: values.token
                    });
                    storeEmail(values.email);
                    storeToken(response.token);
                    login();
                    navigate('/search');
                    break;
                case 'verifyEmail':
                    response = await ApiManager.verifyEmail({
                        email: values.email, recoveryToken: values.token
                    });
                    storeEmail(values.email);
                    storeToken(response.token);
                    login();
                    navigate('/search');
                    break;
                default:
                    break;
            }
        } catch (error) {
            switch (error.message?.code) {
                case 401:
                    handleError({ errorMessage: 'Необходимо повторно войти в аккаунт' });
                    break;
                case 403:
                    handleError({ errorMessage: 'Неверный email или токен (или срок действия токена истек)' });
                    break;
                case 404:
                    handleError({ errorMessage: 'Неверный email или пароль' });
                    break;
                case 409:
                    handleError({ errorMessage: 'У вас уже есть учетная запись. Пожалуйста, войдите (при необходимости, можно сбросить пароль)' });
                    break;
                case 500:
                    handleError({ errorMessage: 'Обновите страницу и повторите попытку. Связь с поддержкой: info@brpadvice.ru' });
                    break;
                case 501:
                    handleError({ errorMessage: 'Ошибка при выполнении запроса. Подождите минуту и повторите попытку. Связь с поддержкой: info@brpadvice.ru' });
                    break;
                case 555:
                    handleError({ errorMessage: 'Ошибка при выполнении запроса. Подождите минуту и повторите попытку. Связь с поддержкой: info@brpadvice.ru' });
                    break;
                default:
                    handleError({ errorMessage: 'Ошибка при выполнении запроса. Подождите минуту и повторите попытку. Связь с поддержкой: info@brpadvice.ru' });
            }
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-card">
                <Formik
                    initialValues={{ email: '', password: '', token: '' }}
                    validationSchema={authValidationSchema(authState)}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <div className="logo-container">
                                <img src="/assets/images/finradar - logo.png" alt="ФИНРАДАР" className="logo-image-authscreen" />
                            </div>
                            <TextField
                                name="email"
                                placeholder="Email"
                                className="text-field"
                                labelIcon={<FiAtSign />}
                                error={errors.email}
                                touched={touched.email}
                            />
                            {authState !== 'recoveryRequest' && authState !== 'verifyEmail' && (
                                <>
                                    <TextField
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Пароль"
                                        togglePasswordVisibility={togglePasswordVisibility}
                                        showPassword={showPassword}
                                        className="text-field"
                                        labelIcon={<FiLock />}
                                        error={errors.password}
                                        touched={touched.password}
                                    />
                                </>
                            )}
                            {(authState === 'passwordReset' || authState === 'verifyEmail') && (
                                <TextField name="token"
                                    placeholder="Токен"
                                    className="text-field"
                                    labelIcon={<FiKey />}
                                    error={errors.token}
                                    touched={touched.token}
                                />
                            )}
                            {authState === 'signIn' && (
                                <div className='reset-password-container'>
                                    <span
                                        className='auth-form-link'
                                        onClick={() => setAuthStateMiddleware('recoveryRequest')}>Забыли пароль?
                                    </span>
                                </div>
                            )}
                            {authState === 'recoveryRequest' && (
                                <div className='reset-password-container'>
                                    <span
                                        className='auth-form-link'
                                        onClick={() => setAuthStateMiddleware('passwordReset')}>Уже есть токен сброса пароля?
                                    </span>
                                </div>
                            )}
                            {authState === 'signUp' && (
                                <div className='reset-password-container'>
                                    <span
                                        className='auth-form-link'
                                        onClick={() => setAuthStateMiddleware('verifyEmail')}>Прошли регистрацию и нужно ввести токен?
                                    </span>
                                </div>
                            )}
                            {authState === 'signUp' && (
                                <TermsCheckbox
                                    agreedToTerms={agreedToTerms}
                                    toggleAgreedToTerms={toggleAgreedToTerms}
                                />
                            )}
                            <div className='error-from-server'>
                                {serverError}
                            </div>
                            <SubmitButton
                                isSubmitting={isSubmitting}
                                loading={loading}
                                authState={authState}
                                className="button-submit"
                            />
                            {authState === 'signIn' && (
                                // <div className="margin-v-20px auth-form-link" onClick={() => setAuthStateMiddleware('signUp')}>
                                //     Еще нет аккаунта? Зарегистрируйтесь!
                                // </div>
                                <div className="margin-v-20px auth-form-link" onClick={handleNavigateToOrderForm}>
                                    Еще нет аккаунта? Зарегистрируйтесь!
                                </div>
                            )}
                            {authState === 'signUp' && (
                                <div className="margin-v-20px auth-form-link" onClick={() => setAuthStateMiddleware('signIn')}>
                                    Уже есть аккаунт? Войдите!
                                </div>
                            )}
                            {authState === 'recoveryRequest' && (
                                <div className="margin-v-20px auth-form-link" onClick={() => setAuthStateMiddleware('signIn')}>
                                    Помните пароль? Войдите!
                                </div>
                            )}
                            {authState === 'passwordReset' && (
                                <div className="margin-v-20px auth-form-link" onClick={() => setAuthStateMiddleware('signIn')}>
                                    Или войти со старым паролем
                                </div>
                            )}
                            {authState === 'verifyEmail' && (
                                <div className="margin-v-20px auth-form-link" onClick={() => setAuthStateMiddleware('recoveryRequest')}>
                                    Забыли пароль? Создайте новый!
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
                <div className='auth-copyright-div'>
                    © Группа BRP ADVICE. С 2012 года
                </div>
            </div>
        </div>
    );
};

export default AuthScreen;
