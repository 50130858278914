import React, { useState } from 'react';
import {
    CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Typography, Box, TablePagination,
    Grid, useMediaQuery,
    Tooltip,
    Divider,
} from '@mui/material';
import CashCardTemplate from './CashCardTemplate';
import { formatDateToGivenFormat, formatDoubleToPaddedString, parseAndFormatDate } from '../../../utils/utils';
import Slicer from '../../common/Slicer';
import ToggleIconButton from '../../common/ToggleIconButton';
import { FilterAlt, TableBarTwoTone, TableViewTwoTone } from '@mui/icons-material';
import MyFilterButton from '../../common/MyFilterButton';
import { myDebugPrint } from '../../../utils/debug';
import PaymentsTable from './PaymentsTable';
import CounterpartyLinks from '../elements/CounterpartyLinks';
import RisksMainTable from './RisksMainTable';
import DateFilter from '../../common/DateFilter';

const validateNumber = (value, roundDigits = 0) => {
    return formatDoubleToPaddedString({ number: value, roundDigits: roundDigits, onErrorReturn: 'нет данных' });
};

const slicerItems = [
    'Недостоверные данные в ЕГРЮЛ',
    'Массовый адрес',
    'Идет банкротство',
    'Нет явных признаков',
    'Встречные обороты >10%',
    'ДДС сопоставимы с выручкой контрагента',
];
const slicerItems2 = [
    'Ликвидировано',
    'Действующее',
    'Не найдено в ЕГРЮЛ/ЕГРИП',
];

const RisksMainData = ({ cashProject, updateCashProjectPayment, filteringInProgress, filteredDateStart, filteredDateEnd, onFilterDates, }) => {
    const [isSlicer, setIsSlicer] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState(slicerItems);
    const [selectedFilters2, setSelectedFilters2] = useState(slicerItems2);
    const [selectedCompany, setSelectedCompany] = useState({ TaxNumber: null, ShortName: null });
    const [paymentsKey, setPaymentsKey] = useState(0); // Add a state to manage the key

    const togglePayments = ({ turnOn }) => {
        setPaymentsKey(prevKey => prevKey + 1); // Increment the key to force remount
    };

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const slicerGridSize = isSmallScreen ? 12 : 6;

    const toggleSlicer = () => setIsSlicer(!isSlicer);

    const toggleFilter = (filter) => {
        setSelectedFilters((prevFilters) =>
            prevFilters.includes(filter)
                ? prevFilters.filter((f) => f !== filter)
                : [...prevFilters, filter]
        );
    };

    const toggleFilter2 = (filter) => {
        setSelectedFilters2((prevFilters) =>
            prevFilters.includes(filter)
                ? prevFilters.filter((f) => f !== filter)
                : [...prevFilters, filter]
        );
    };

    const selectAllFilters = () => {
        setSelectedFilters(slicerItems);
    };

    const deselectAllFilters = () => {
        setSelectedFilters([]);
    };
    const selectAllFilters2 = () => {
        setSelectedFilters2(slicerItems2);
    };

    const deselectAllFilters2 = () => {
        setSelectedFilters2([]);
    };

    const applyFilters = (entity) => {
        const filter1Match = (
            selectedFilters.includes('Недостоверные данные в ЕГРЮЛ') && (entity.InvalidityScore > 0 || entity.BadStatusScore > 0)
        ) || (
                selectedFilters.includes('Массовый адрес') && entity.MassAddressScore > 0
            ) || (
                selectedFilters.includes('Идет банкротство') && entity.BankruptcyScore > 0
            ) || (
                selectedFilters.includes('Встречные обороты >10%') && (Math.min(entity.inflows, entity.outflows) / Math.max(entity.inflows, entity.outflows) > 0.1)
            ) || (
                selectedFilters.includes('ДДС сопоставимы с выручкой контрагента') && entity.Revenue && (Math.max(entity.inflows, entity.outflows) / (entity.Revenue * 1000) > 0.6)
            ) || (
                selectedFilters.includes('Нет явных признаков')
                && !(entity.InvalidityScore > 0)
                && !(entity.BadStatusScore > 0)
                && !(entity.MassAddressScore > 0)
                && !(entity.BankruptcyScore > 0)
            );

        const filter2Match = (
            selectedFilters2.includes('Ликвидировано') && entity.LiquidationScore > 0
        ) || (
                selectedFilters2.includes('Действующее') && (!entity.LiquidationScore || entity.LiquidationScore === 0)
            ) || (
                selectedFilters2.includes('Не найдено в ЕГРЮЛ/ЕГРИП') && (!entity.OGRN && !entity.ShortName)
            );

        const companyFilterMatch = (
            selectedCompany.TaxNumber && (entity.CounterpartyINN == selectedCompany.TaxNumber)
        ) || !selectedCompany.TaxNumber;

        return filter1Match && filter2Match && companyFilterMatch;
    };

    const filteredRisks = cashProject.risks.filter(applyFilters);

    const filterCompany = ({ TaxNumber, ShortName }) => {
        myDebugPrint(`filterCompany: TaxNumber, ShortName: ${TaxNumber}, ${ShortName}`)
        if (!TaxNumber || selectedCompany.TaxNumber === TaxNumber) {
            setSelectedCompany({ 'TaxNumber': null, 'ShortName': null })
            return;
        }
        setSelectedCompany({ 'TaxNumber': TaxNumber, 'ShortName': ShortName })
        myDebugPrint(`filterCompany: selected company: ${JSON.stringify(selectedCompany)}`)
        return;
    }

    const handleFilterDates = async (dateFrom, dateTo) => {
        // setFilterDatesStart(dateFrom);
        // setFilterDatesEnd(dateTo);
        filterCompany({ TaxNumber: null })
        await onFilterDates(dateFrom, dateTo);
    }


    return (
        <CashCardTemplate
            title={<Box>
                <span>
                    Анализ рисков по контрагентам
                </span>
            </Box>}
            subheader="Ключевые данные по основным контрагентам предпринимателям и юр.лицам"
            action={
                <ToggleIconButton
                    icon={<FilterAlt />}
                    isOn={isSlicer}
                    onSelectAll={toggleSlicer}
                    onDeselectAll={toggleSlicer}
                />
            }
            cardcontent={
                <CardContent>
                    {isSlicer &&
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={slicerGridSize / 2} key="slicer-indicators">
                                <Slicer
                                    title="Признаки риска"
                                    items={slicerItems}
                                    selectedItems={selectedFilters}
                                    onToggle={toggleFilter}
                                    onSelectAll={selectAllFilters}
                                    onDeselectAll={deselectAllFilters}
                                    order={true}
                                    columnsOnXs={1}
                                    columnsOnMD={1}
                                />
                            </Grid>
                            <Grid item xs={6} md={slicerGridSize / 2} key="slicer-status" >
                                <Slicer
                                    title="Статус"
                                    items={slicerItems2}
                                    selectedItems={selectedFilters2}
                                    onToggle={toggleFilter2}
                                    onSelectAll={selectAllFilters2}
                                    onDeselectAll={deselectAllFilters2}
                                    order={true}
                                    columnsOnXs={1}
                                    columnsOnMD={1}
                                />
                            </Grid>
                            <Grid item xs={12} md={slicerGridSize} >
                                <Box>

                                    <DateFilter
                                        filterTitle='Период анализа'
                                        minDate={formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' })}
                                        maxDate={formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' })}
                                        filteredDateStart={filteredDateStart ? filteredDateStart : formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' })}
                                        filteredDateEnd={filteredDateEnd ? filteredDateEnd : formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' })}
                                        onFilter={handleFilterDates}
                                        filteringInProgress={filteringInProgress}
                                    />
                                </Box>
                                <Box>
                                    {selectedCompany && selectedCompany.TaxNumber &&
                                        <Grid item xs={6}>
                                            <Slicer
                                                key="filter-company"
                                                title="Контрагент"
                                                items={[
                                                    (selectedCompany.ShortName ? selectedCompany.ShortName + ' (' : '')
                                                    + (`ИНН ${selectedCompany.TaxNumber}`)
                                                    + (selectedCompany.ShortName ? ')' : '')
                                                ]}
                                                selectedItems={[
                                                    (selectedCompany.ShortName ? selectedCompany.ShortName + ' (' : '')
                                                    + (`ИНН ${selectedCompany.TaxNumber}`)
                                                    + (selectedCompany.ShortName ? ')' : '')
                                                ]}
                                                onToggle={() => filterCompany({ TaxNumber: null })}
                                                onSelectAll={() => filterCompany({ TaxNumber: null })}
                                                onDeselectAll={() => filterCompany({ TaxNumber: null })}
                                                order={true}
                                                columnsOnXs={1}
                                                columnsOnMD={1}
                                            />
                                        </Grid>
                                    }
                                </Box>
                            </Grid>

                        </Grid>
                    }
                    <RisksMainTable
                        filteredRisks={filteredRisks}
                        setSelectedCompany={setSelectedCompany}
                        filteredByDates={
                            formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' }) != filteredDateStart
                            || formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' }) != filteredDateEnd
                        }
                        filteredDateStart={filteredDateStart}
                        filteredDateEnd={filteredDateEnd}
                        filteredByCompany={selectedCompany && selectedCompany.TaxNumber}
                    />
                    {selectedCompany && selectedCompany.TaxNumber &&
                        <Box>
                            <br />
                            <Divider />
                            <br />
                            <PaymentsTable
                                key={paymentsKey} // Add the key prop
                                projectId={cashProject.projectId}
                                taxNumbers={[selectedCompany.TaxNumber]}
                                currencyCode={'810'}
                                recordsPerPage={10}
                                updateCashProjectPayment={updateCashProjectPayment}
                                filteredByDates={
                                    formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' }) != filteredDateStart
                                    || formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' }) != filteredDateEnd
                                }
                                filteredDateStart={filteredDateStart}
                                filteredDateEnd={filteredDateEnd}
                                filteredByTypes={null}
                                readOnly={cashProject.accessLevel && cashProject.accessLevel.toLowerCase() == 'viewer'}
                            />
                        </Box>
                    }
                </CardContent>
            }
            initalFolded={false}
        />
    );
};

export default RisksMainData;
