import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Card, CardHeader, CardContent, Grid, List, ListItem, Typography, } from '@mui/material';
import { shareOfTransactions, formatDateToGivenFormat } from '../../utils/utils';
import { myDebugPrint } from '../../utils/debug';
import AccountOverallData from './cards/AccountOverallData';
import OverallByMonths from './cards/OverallsByMonths';
import OverallByTransactionTypes from './cards/OverallsByTransactionTypes';
import CashCardTemplate from './cards/CashCardTemplate';
import CounterpartiesCard from './cards/CounterpartiesCard';
import RecalculateProjectCard from './cards/RecalculateProjectCard';
import BusinessCard from '../BusinessCard';
import FileProcessingResults from './cards/FileProcessingResults';
import StatementsWithZeroTurnover from './cards/StatementsWithZeroTurnover';
import MissingAccounts from './cards/MissingAccounts';
// import BookVATUploader from './BookVATUploader';



const ReportCashData = ({
    cashProject, cashUploader, fetchingReanalyzer, reanalyzePayments,
    updateCashProjectPayment, handleFetchTransactionsByDates, filteringInProgress,
    minDate, maxDate, filteredDateStart, filteredDateEnd,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const overall_by_accounts_widget = () => {
        let widgets = [];
        {
            cashProject.accountStatements.forEach((account, index) => {
                const accountNumber = account.AccNmbr
                const inn = account.INN
                const currency = account.CurrencyCode

                widgets.push(<AccountOverallData
                    key={accountNumber}
                    index={index}
                    inn={inn}
                    currency={currency}
                    accountNumber={accountNumber}
                    earliestDate={cashProject.minDate}
                    totals={account.Totals}
                    statements={account.Statements}
                />);
            }
            )
        }
        return widgets;
    }

    const filterTransactionsByCurrency = (currencyCode) => {
        myDebugPrint(`filterTransactionsByCurrency currencyCode: ${currencyCode}`)
        let filtered = cashProject.transactiontype.filter((item) => item.CurrencyCode == currencyCode)
        return filtered
    }

    return (
        <Box>
            {
                cashProject.processingStatus != 'done' &&
                <Box>
                    <Typography variant='body2'>
                        Идет обработка загруженных выписок...
                        {cashProject.processingStatus
                            && cashProject.processingStatus.includes('/') ?
                            shareOfTransactions(cashProject.processingStatus) : ''
                        }
                        <br />
                        <br />
                        Обычно обработка выписок 1C и анализ всех транзакций занимает несколько минут, а для Excel - от нескольких минут до 1 дня.
                        <br />
                        <br />
                        Статус на этой странице не будет обновляться автоматически.
                        Перезагрузите страницу через 5-7 минут.
                    </Typography>
                </Box>

            }
            {cashProject.processingStatus == 'done' &&
                <Box>
                    <CashCardTemplate
                        title='Добавить выписку в проект'
                        subheader={(cashProject.accessLevel && cashProject.accessLevel.toLowerCase() == 'viewer') ?
                            <span>У вас нет прав для добавления выписок. При необходимости обратитесь к владельцу проекта.</span>
                            : null}
                        initalFolded={true}
                        cardcontent={
                            <CardContent>
                                <List>
                                    {(!cashProject.accessLevel || cashProject.accessLevel.toLowerCase() != 'viewer') ? cashUploader : null}
                                </List>
                            </CardContent>
                        }
                    />
                    {/* <CashCardTemplate
                        title='Добавить книгу продаж или книгу покупок'
                        subheader='Эта функция доступна по отдельной подписке. Узнайте детали у вашего менеджера'
                        initalFolded={true}
                        cardcontent={
                            <CardContent>
                                <List>
                                    <BookVATUploader
                                        projectId={cashProject.projectId}
                                    />
                                </List>
                            </CardContent>
                        }
                    /> */}

                    {cashProject.myCompanies && cashProject.myCompanies.length > 0 &&
                        <Box sx={{ marginBottom: '45px' }}>
                            {
                                cashProject.myCompanies.map((entity, index) => (
                                    < BusinessCard
                                        key={entity.taxNumber + '_' + index}
                                        entity={entity}
                                        showCEO={true}
                                        newtab={true}
                                    // showShareholders={true}
                                    />
                                ))
                            }
                        </Box>
                    }
                    {cashProject.accountStatements &&
                        <Box>
                            <FileProcessingResults
                                accountStatements={cashProject.accountStatements}
                            />
                        </Box>
                    }
                    {cashProject.accountStatements && cashProject.collectedTaxNumbersAndAccounts &&
                        <Box>
                            <MissingAccounts
                                accountStatements={cashProject.accountStatements}
                                collectedTaxNumbersAndAccounts={cashProject.collectedTaxNumbersAndAccounts}
                            />
                        </Box>
                    }
                    {/* <StatementsWithZeroTurnover
                        accountStatements={cashProject.accountStatements}
                    /> */}
                    {cashProject.accountStatements && cashProject.accountStatements.length > 0 &&
                        <Box>
                            <CashCardTemplate
                                title='Общие сведения об оборотах по счетам'
                                cardcontent={
                                    <CardContent>
                                        <List>
                                            {overall_by_accounts_widget()}
                                        </List>
                                    </CardContent>
                                }
                            />
                        </Box>
                    }
                    {cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0 &&
                        <OverallByMonths
                            transactionData={cashProject.overalls_by_months}
                            closingBalances={cashProject.overalls_closing_balances_by_months}
                        />
                    }
                    {(cashProject.transactiontype && cashProject.transactiontype.length > 0) &&
                        <OverallByTransactionTypes
                            currencyCode='810'
                            transactionData={filterTransactionsByCurrency('810')}
                            projectId={cashProject.projectId}
                            updateCashProjectPayment={updateCashProjectPayment}
                            handleFetchTransactionsByDates={handleFetchTransactionsByDates}
                            minDate={formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' })}
                            maxDate={formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' })}
                            filteredDateStart={filteredDateStart}
                            filteredDateEnd={filteredDateEnd}
                            filteringInProgress={filteringInProgress}
                            accessLevel={cashProject.accessLevel}
                        />
                    }
                    {/* } */}
                    {((cashProject.overalls_by_accounts && cashProject.overalls_by_accounts.length > 0) ||
                        (cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0)) && cashProject &&
                        <CounterpartiesCard
                            projectId={cashProject.projectId}
                            currencyCode='810'
                            updateCashProjectPayment={updateCashProjectPayment}
                            minDate={formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' })}
                            maxDate={formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' })}
                            filteredDateStart={filteredDateStart}
                            filteredDateEnd={filteredDateEnd}
                            filteringInProgress={filteringInProgress}
                            onFilterDates={handleFetchTransactionsByDates}
                            accessLevel={cashProject.accessLevel}
                        />
                    }
                    {((cashProject.overalls_by_accounts && cashProject.overalls_by_accounts.length > 0) ||
                        (cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0)) &&
                        <Box >
                            <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ width: '100%', }} >
                                        <RecalculateProjectCard
                                            fetchingReanalyzer={fetchingReanalyzer}
                                            reanalyzePayments={reanalyzePayments}
                                            type='reanalyze'
                                        />
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12} md={4}>
                                <Box sx={{ width: '100%', }} >

                                    <RecalculateProjectCard
                                        fetchingReanalyzer={fetchingReanalyzer}
                                        reanalyzePayments={reanalyzePayments}
                                        type='retotal'
                                    />
                                </Box>
                            </Grid> */}
                            </Grid >
                        </Box>}
                </Box>
            }
        </Box >
    );
}

export default ReportCashData;



